import React from 'react';
import tools from '../../img/tools.png';
import styled from 'styled-components';

const Toolpic = styled.div`
    img {
        width: 100%;
    }
`;


function Home(props) {
    return(
        <div className="Home">
            <h2>Tell Your Story</h2>
            <p>
                You have a story. Your company has a story. We help you find the best way to tell your story. Period.
                Whether it’s with sound, picture, animation – we can help you find your voice.
            </p>
            <p>
                With over 25 years’ experience helping people and organizations tell their stories, we have everything
                you need to get yours out there and make it compelling.  
            </p>
            <h2>What We Do</h2>
            <p>At MMC, we offer a variety of services to help you tell your story, including:</p>
            <p>
                <div className="list-group list-group-flush">
                    <div class="list-group-item list-group-item-action flex-column align-items-start">
                        <div className="d-flex w-100 justify-content-between">
                            <h5>Studio production, both audio and video</h5>
                        </div>
                        <p className="mb-1">Record your projects in a professional studio environment to ensure the highest fidelity possible.</p>
                    </div>
                    <div class="list-group-item list-group-item-action flex-column align-items-start">
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">Location production, both audio and video</h5>
                        </div>
                        <p className="mb-1">Need us to come to you? We've got you covered. Our on-site production can be there to accomodate your vision.</p>
                    </div>
                    <div class="list-group-item list-group-item-action flex-column align-items-start">
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">Video and audio editing</h5>
                        </div>
                        <p className="mb-1">If you have your production recorded, but are looking for an editor, then you've come to the right place.</p>
                    </div>
                    <div class="list-group-item list-group-item-action flex-column align-items-start">
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">"Sweetening" of your existing products</h5>
                        </div>
                        <p className="mb-1">Not everyone will be satisfied with an old commercial, interview, or other such publication. That's where we come in. Our tools allow us to spruce up any A/V project that requires a little TLC.</p>
                    </div>
                    <div class="list-group-item list-group-item-action flex-column align-items-start">
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">Consultation for your self-produced Podcasts, VLOGs and Blogs</h5>
                        </div>
                        <p className="mb-1">Everyone has to start somewhere. Let us help you figure out the best course of action for creating and maintaining your web presence.</p>
                    </div>
                    <div class="list-group-item list-group-item-action flex-column align-items-start">
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">Restoration services for legacy media</h5>
                        </div>
                        <p className="mb-1">We've all been here. You've dug up an old cassette that has your most cherished summertime mixtape. Or perhaps it's an old home movie that you don't have the means to view anymore. Don't fret. We're armed with the knowhow and a suite of conversion tools that allow us to preserver your most precious memories for the digital age.</p>
                    </div>
                    <div class="list-group-item list-group-item-action flex-column align-items-start">
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">Full-stack web development, management, and other IT services</h5>
                        </div>
                        <p className="mb-1">From plugging in the necessary cables to designing a slick user interface and everything in between, we can help you at any stage to get your dream website up and running.</p>
                    </div>
                </div>
            </p>
            <h2>What We Use</h2>
            <p>We have a variety of tools from top manufacturers that enable us to help you tell your story.</p>
            <Toolpic>
                <img src={tools}/>
            </Toolpic>
            <hr/>
            <h2>What Our "Storytellers" Have to Say</h2>
            <p>More to come soon...</p>
        </div>
    );
}

export default Home;