import React from 'react';
import Button from 'react-bootstrap/Button'

function NoMatch() {
    return(
        <div className="NoMatch">
            <h1>Error 404 - Page Not Found</h1>
        </div>
    );
}

export default NoMatch;