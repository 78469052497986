import React from 'react';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import NoMatch from './components/pages/NoMatch';
import { Layout } from './components/Layout';
import { NavBar } from './components/NavBar';
import { Jumbotron } from './components/Jumbotron';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

const Styles = styled.div`
  h6 {
    font-weight: normal;
    color: black;
    font-size: 0.66em;
    text-align: center;
  }
`;

let dt = new Date();
let year = dt.getFullYear();

function App() {
  return (
    <div className="App">
      <NavBar/>
      <Jumbotron/>
      <Layout>
        <Router>
          <Switch>
            <Route exact path="/" component={Home}></Route>
            <Route path="/about" component={About}></Route>
            <Route path="/contact" component={Contact}></Route>
            <Route component={NoMatch}></Route>
          </Switch>
        </Router>
      </Layout>
      <Styles>
        <h6>&copy;{year} Moody Media Consultants</h6>
      </Styles>
    </div>
  );
}

export default App;
