import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import styled from 'styled-components';
import logo from '../img/logo.png';

const Styles = styled.div`
    .navbar {
        background: #333;
    }

    .navbar-brand, .navbar-nav .nav-link {
        color: #fff;

        &:hover {
            color: gold;
        }
    }

    .navbar-brand img {
        height: 45px;
    }

    .navbar-toggler {
        background-color: #ffc20e;
    }
`;

export const NavBar = () => (
    <Styles>
        <Navbar expand="lg">
            <Navbar.Brand href="/"><img src={logo}/></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    <Nav.Item>
                        <Nav.Link href="/">Home</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/about">About</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/contact">Contact</Nav.Link>
                    </Nav.Item>
                    
                    {/*
                    <Nav.Item>
                        <Nav.Link href="/about">About</Nav.Link>
                    </Nav.Item>
                    */}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    </Styles>
);