import React from 'react';
import Button from 'react-bootstrap/Button';
import HorizontalCard from '../HorizontalCard';

function About() {
    let employees = [
        {
            name: "Vance",
            image: "vance.png",
            blurb: "Dear Sir stroke Madame, I am writing to inform you of a fire that has broken out at the premesis of...wait no that's too formal. Dear Sir stroke Madame, FIRE exclamation mark. FIRE exclamation mark. HELP ME exclamation mark. 123 Carendon Road. Looking forward to hearing from you. All the best, Maurice Moss."
        },
        {
            name: "Ethan",
            image: "ethan.png",
            blurb: "Dear Sir stroke Madame, I am writing to inform you of a fire that has broken out at the premesis of...wait no that's too formal. Dear Sir stroke Madame, FIRE exclamation mark. FIRE exclamation mark. HELP ME exclamation mark. 123 Carendon Road. Looking forward to hearing from you. All the best, Maurice Moss."
        }
    ];

    return(
        <div className="about">
            {employees.map(item => <HorizontalCard img={`img/${item.image}`} title={item.name} desc={item.blurb}/>)}
        </div>
    );
}

export default About;