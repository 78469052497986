import React from 'react';
import Button from 'react-bootstrap/Button';
import {FaFacebook, FaTwitter, FaInstagram, FaMailBulk} from 'react-icons/fa';

function Contact() {
    return(
        <div className="contact">
            <p>Get in touch via any of these platforms.</p>
            <a className="socialIcon socialFacebook" href="https://www.facebook.com/VanceAMoodyMediaProduction">
                <FaFacebook/>
            </a>
            <a className="socialIcon socialTwitter" href="https://twitter.com/Moodymediacons1">
                <FaTwitter/>
            </a>
            <a className="socialIcon socialInstagram" href="https://instagram.com/moodymediaconsultants">
                <FaInstagram/>
            </a>
            <a className="socialIcon socialEmail" href="mailto:moodymediaconsultants@gmail.com">
                <FaMailBulk/>
            </a>
        </div>
    );
}

export default Contact;